import { ClientInvoicesColumnData } from './types';

export const columns: ClientInvoicesColumnData[] = [
  { width: 100, label: 'Status', itemKey: 'status' },
  { width: 120, label: 'Invoice Number', itemKey: 'invoiceNumber' },
  { width: 120, label: 'Period', itemKey: 'period' },
  { width: 120, label: 'Date Sent', itemKey: 'dateSent' },
  { width: 120, label: 'Payment Due Date', itemKey: 'dueDate' },
  { width: 120, label: 'Payment Date', itemKey: 'paidDate' },
  { width: 80, label: 'Amount', itemKey: 'amount' },
  { width: 120, label: '', itemKey: 'id' },
];
