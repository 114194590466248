import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { adaptiveColumnBoxSx } from 'components/common.styles';
import ClientInvoicesTable from 'components/table/client/Invoices';

export default function InvoicesPage() {
  return (
    <Box sx={adaptiveColumnBoxSx}>
      <Typography variant={'h6'} fontWeight={'800'} fontSize={'24px'}>
        Invoices
      </Typography>
      <ClientInvoicesTable />
    </Box>
  );
}
