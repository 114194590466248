import { SuperAdminProjectUsersColumnData } from './types';

export const columns: SuperAdminProjectUsersColumnData[] = [
  {
    width: 120,
    label: 'User name',
    itemKey: 'name',
  },
  {
    width: 150,
    label: 'User email',
    itemKey: 'email',
  },
  {
    width: 150,
    label: 'User position',
    itemKey: 'position',
  },
  {
    width: 150,
    label: 'Role',
    itemKey: 'role',
  },
  {
    width: 130,
    label: 'Status',
    itemKey: 'status',
  },
  { width: 150, label: '', itemKey: 'manageLink' },
];
