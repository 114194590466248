import RemoveIcon from '@mui/icons-material/RemoveCircle';
import SendIcon from '@mui/icons-material/SendRounded';
import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableCell from '@mui/material/TableCell';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import useToggle from 'hooks/toggle';
import { UserAccessStatus } from 'utils/enums';
import { useUserResendInviteMutation } from 'api/mutations/users/resend-invite';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import { userAccessStatusLabelMapping, userRoleLabelMapping } from 'utils/labels';
import { useProjectRemoveUserMutation } from 'api/mutations/projects/remove-user';
import { SuperAdminProjectUsersTableItem } from '../types';

function UserActionsTableCell({
  id: userId,
  name,
  status,
}: Pick<SuperAdminProjectUsersTableItem, 'id' | 'name' | 'status'>) {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId = '' } = useParams();
  const [removeModalVisible, toggleRemoveModal] = useToggle(false);

  const { mutateAsync: resendInvite, isPending: isLoadingResendInvite } = useUserResendInviteMutation({ userId });
  const { mutateAsync: removeUser, isPending: isLoadingRemoveUser } = useProjectRemoveUserMutation();

  const resendInvitation = () => {
    return resendInvite()
      .then(() => enqueueSnackbar('The invitation has been sent!', { variant: 'success' }))
      .catch(handleAxiosError);
  };

  return (
    <TableCell>
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {status !== UserAccessStatus.ACTIVE && (
          <Tooltip title={'Resend invitation'}>
            <IconButton color={'info'} onClick={resendInvitation} disabled={isLoadingResendInvite}>
              {isLoadingResendInvite ? <CircularProgress size="1.5rem" /> : <SendIcon />}
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={'Remove access'}>
          <IconButton color={'warning'} onClick={toggleRemoveModal} disabled={isLoadingRemoveUser}>
            {isLoadingRemoveUser ? <CircularProgress size="1.5rem" /> : <RemoveIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      {removeModalVisible && (
        <Dialog open={true} onClose={toggleRemoveModal}>
          <DialogTitle>Remove confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove access for the user <b>{name}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleRemoveModal}>Cancel</Button>
            <Button
              variant="contained"
              color="error"
              onClick={async () => {
                await removeUser({
                  projectId,
                  userId,
                });
                toggleRemoveModal();
                enqueueSnackbar(`User's access has been removed!`, { variant: 'success' });
              }}
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </TableCell>
  );
}

export function itemContent(
  _index: number,
  { id, name, email, position, role, status }: SuperAdminProjectUsersTableItem,
) {
  return (
    <>
      <TableCell>
        <Typography variant={'body2'} fontWeight={'700'} sx={{ wordWrap: 'break-word' }}>
          {name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
          <a href={`mailto:${email}`}>{email}</a>
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
          {position}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
          {userRoleLabelMapping[role]}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
          {userAccessStatusLabelMapping[status]}
        </Typography>
      </TableCell>

      <UserActionsTableCell id={id} name={name} status={status} />
    </>
  );
}
