import dayjs from 'dayjs';
import { Timestamp } from './types';

const defaultFormatCurrencyOptions = {
  style: 'currency',
  currencyDisplay: 'symbol',
  currency: 'USD',
  maximumFractionDigits: 2,
  useGrouping: true,
};

export const formatCurrency = (value: number, options: Intl.NumberFormatOptions = {}) => {
  const mergedOptions = { ...defaultFormatCurrencyOptions, ...options };
  const amountFormatter = new Intl.NumberFormat('en', mergedOptions);

  return amountFormatter.format(value);
};

const percentFormatter = new Intl.NumberFormat('en', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export const formatPercent = (percent: number) => percentFormatter.format(percent / 100);

export const formatDate = (date?: Timestamp) => (date ? dayjs(date).format('MMM DD, YYYY') : ' ');

type FormatPeriodOptions = {
  hideEndDate?: boolean;
};

const formatPeriodDefaultOptions: FormatPeriodOptions = {
  hideEndDate: false,
};

export const formatPeriod = (from: string, to: string, options: FormatPeriodOptions = {}): string => {
  const opts = { ...formatPeriodDefaultOptions, ...options };
  const dateFrom = dayjs(from);
  const dateTo = dayjs(to);

  const isSameMonth = dateFrom.month() === dateTo.month();
  if (isSameMonth) {
    const _from = dateFrom.format('MMM D');
    const _to = opts.hideEndDate ? null : dateTo.format('D, YYYY');
    return [_from, _to].filter(Boolean).join(' - ');
    // -> Nov 1 - 4, 2023
  }

  const isSameYear = dateFrom.year() === dateTo.year();
  if (isSameYear) {
    const _from = dateFrom.format('MMM D');
    const _to = opts.hideEndDate ? null : dateTo.format('MMM D, YYYY');
    return [_from, _to].filter(Boolean).join(' - ');
    // -> Oct 1 - Nov 4, 2023
  }

  const _from = dateFrom.format('MMM D YYYY');
  const _to = opts.hideEndDate ? null : dateTo.format('MMM D YYYY');
  return [_from, _to].filter(Boolean).join(' - ');
  // -> Oct 1 2023 - Nov 4 2025
};

export type Basis = 'Hourly' | 'Daily' | 'Monthly' | 'Flat';

const formatAmount = (value: number) => formatCurrency(value, { minimumFractionDigits: 0 });

export const formatRate = (basis: Basis, amount: number, rate: number, dailyRate: number) => {
  switch (basis) {
    case 'Hourly':
      return `${formatAmount(rate)}/hour`;
    case 'Daily':
      return `${formatAmount(dailyRate)}/day`;
    case 'Monthly':
      return `${formatAmount(amount)}/month`;
    case 'Flat':
      return `${formatAmount(amount)}`;
    default:
      return '';
  }
};
