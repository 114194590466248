import { useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import generateVirtuosoTableComponents from 'components/table/virtuoso.common';
import generateHeaderContent from 'components/table/header-content';
import Wrap from 'components/table/Wrap';
import { GetProjectUsersResponse } from 'api/queries/projects/get-project-users';
import { itemContent } from './components/RowContent';
import { columns } from './config';
import { SuperAdminProjectUsersTableItem } from './types';
import LoadingFooter from '../../footer-content';

const VirtuosoTableComponents = generateVirtuosoTableComponents(columns);
const fixedHeaderContent = generateHeaderContent(columns);

type Props = {
  data?: GetProjectUsersResponse;
  isLoading: boolean;
};

export default function SuperAdminProjectUsersTable({ data, isLoading }: Props) {
  const filteredRows = useMemo(
    () =>
      (data?.users || []).map<SuperAdminProjectUsersTableItem>((user) => ({
        id: user.id,
        name: [user.firstName, user.lastName].filter(Boolean).join(' ') || 'N/A',
        email: user.email,
        position: user.position || 'N/A',
        role: user.roles[0],
        status: user.status,
        manageLink: '',
      })),
    [data],
  );

  return (
    <Wrap noData={filteredRows.length === 0}>
      <TableVirtuoso
        fixedFooterContent={isLoading ? LoadingFooter : undefined}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={itemContent}
        data={filteredRows}
        components={{
          ...VirtuosoTableComponents,
          EmptyPlaceholder: isLoading ? undefined : VirtuosoTableComponents.EmptyPlaceholder,
        }}
      />
    </Wrap>
  );
}
