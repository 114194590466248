import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API_PROJECTS, API_USERS } from 'constants/apiUrl';
import axiosInstance from 'api/axios/instance';
import { getProjectUsersQueryKey } from 'api/queries/projects/get-project-users';
import { ProjectUserParams } from './add-user';

async function removeUserFromProject(params: ProjectUserParams) {
  await axiosInstance.delete(API_PROJECTS + '/' + params.projectId + '/' + API_USERS + '/' + params.userId);
}

const mutationKey = ['project-remove-user'] as const;

export function useProjectRemoveUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: removeUserFromProject,
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({ queryKey: getProjectUsersQueryKey(params.projectId) });
    },
  });
}
