import { CircularProgress } from '@mui/material';
import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

const Button = styled.button`
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #cbd5e1;
  padding: 0;
  color: #1e293b;
  font-size: 14px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  :hover {
    background-color: #f8fafc;
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    text-decoration: none !important;
  }
`;

interface ButtonProps extends PropsWithChildren {
  loading?: boolean;
  iconSrc?: string;
  onClick?: () => void;
}

function SecondaryButton({ loading = false, onClick = () => {}, children }: ButtonProps) {
  return (
    <Button onClick={onClick}>
      <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</span>
      {loading && <CircularProgress sx={{ color: '#CBD5E1', position: 'absolute' }} size="1.2rem" />}
    </Button>
  );
}

export default SecondaryButton;
