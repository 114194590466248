import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API_USERS } from 'constants/apiUrl';
import axiosInstance from 'api/axios/instance';
import { QUERY_KEY as USERS_QUERY_KEY } from 'api/queries/users/get-many';

export type RemoveUserParams = {
  userId: string;
};

async function removeUser(params: RemoveUserParams) {
  await axiosInstance.delete(API_USERS + '/' + params.userId);
}

const mutationKey = ['remove-user'] as const;

export function useRemoveUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: removeUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
    },
  });
}
