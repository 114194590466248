import { MenuItem, MenuList, Paper, Popover } from '@mui/material';
import { useProfileOptions } from './options';

interface ProfileMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose(): void;
}

export default function ProfileDropdown({ open, anchorEl, handleClose }: ProfileMenuProps) {
  const profileOptions = useProfileOptions();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: -5 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Paper>
        <MenuList>
          {profileOptions.map(({ name, onClick, Icon }) => (
            <MenuItem
              sx={{ display: 'flex', gap: '8px' }}
              key={name}
              onClick={() => {
                onClick();
                handleClose();
              }}
            >
              <Icon /> {name}
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </Popover>
  );
}
