import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { LoginResponse } from 'api/types';
import { API_AUTH_REFRESH_TOKEN, API_URL } from 'constants/apiUrl';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/localStorageKeys';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
});

const axiosRefreshTokenInstance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  },
);

axiosRefreshTokenInstance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    }

    return config;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  },
);

function refreshTokenLogic() {
  return axiosRefreshTokenInstance
    .post<LoginResponse>(API_AUTH_REFRESH_TOKEN)
    .then(({ data }) => {
      localStorage.setItem(ACCESS_TOKEN, data.accessToken);
      localStorage.setItem(REFRESH_TOKEN, data.refreshToken);
    })
    .catch((error: AxiosError) => {
      const { status } = error?.response || {};
      if (status === 401 || status === 403) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        location.pathname = '/';
      }
    });
}

createAuthRefreshInterceptor(axiosInstance, refreshTokenLogic);

export default axiosInstance;
