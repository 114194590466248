import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { adaptiveColumnBoxSx } from 'components/common.styles';
import ClientResourcesTable from 'components/table/client/Resources';

export default function ResourcesPage() {
  return (
    <Box sx={adaptiveColumnBoxSx}>
      <Typography variant={'h6'} fontWeight={'800'} fontSize={'24px'}>
        Available resources
      </Typography>
      <ClientResourcesTable />
    </Box>
  );
}
