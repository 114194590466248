import { useRef } from 'react';
import Avatar from 'components/UI/Avatar';
import useToggle from 'hooks/toggle';
import { useAuth } from 'context/auth';
import ProfileDropdown from './ProfileDropdown';

function Profile() {
  const { isAuthorised } = useAuth();
  const [dropdownOpen, toggleDropdown] = useToggle(false);
  const dropdownAnchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Avatar ref={dropdownAnchorRef} onClick={toggleDropdown} />
      {isAuthorised && (
        <ProfileDropdown open={dropdownOpen} anchorEl={dropdownAnchorRef?.current} handleClose={toggleDropdown} />
      )}
    </>
  );
}

export default Profile;
