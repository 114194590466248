export enum UserRole {
  CLIENT = 'client',
  PROJECT_ADMIN = 'project-admin',
  SUPER_ADMIN = 'super-admin',
}

export enum UserAccessStatus {
  ACTIVE = 'active',
  INVITED = 'invited',
}
