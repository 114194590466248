import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import AppDrawer from './AppDrawer';

function MobileNavMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  return (
    <>
      <IconButton onClick={handleOpen}>
        <MenuIcon />
      </IconButton>
      <AppDrawer open={isOpen} handleClose={handleClose} />
    </>
  );
}

export default MobileNavMenu;
