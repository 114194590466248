import { TableVirtuoso } from 'react-virtuoso';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import generateVirtuosoTableComponents from 'components/table/virtuoso.common';
import generateHeaderContent from 'components/table/header-content';
import { useAuth } from 'context/auth';
import { GetProjectResponse, InvoiceStatus, useProjectGetOneQuery } from 'api/queries/projects/get-one';
import { formatPeriod } from 'utils/format';
import Wrap from 'components/table/Wrap';
import { itemContent } from './components/RowContent';
import { columns } from './config';
import { ClientInvoicesTableItem } from './types';

const VirtuozoTableComponents = generateVirtuosoTableComponents(columns);
const fixedHeaderContent = generateHeaderContent(columns);

export default function ClientInvoicesTable() {
  const { selectedProjectId } = useAuth();
  const { data } = useProjectGetOneQuery(selectedProjectId || '');
  const rows = useMemo(() => dataAdapter(data), [data]);

  return (
    <Wrap noData={rows.length === 0}>
      <TableVirtuoso
        fixedHeaderContent={fixedHeaderContent}
        itemContent={itemContent}
        data={rows}
        components={VirtuozoTableComponents}
      />
    </Wrap>
  );
}

function dataAdapter(data?: GetProjectResponse): ClientInvoicesTableItem[] {
  if (!data) return [];

  return data.invoices
    .filter((invoice) => ![InvoiceStatus.Draft, InvoiceStatus.ReadyToSend].includes(invoice.status))
    .sort((a, b) => dayjs(b.dateSent).diff(dayjs(a.dateSent)))
    .map((invoice) => ({
      ...invoice,
      period: formatPeriod(invoice.dateFrom, invoice.dateTo),
    }));
}
