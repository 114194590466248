import { Link, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { Link as RouterLink } from 'react-router-dom';
import { formatDate, formatPercent, formatRate } from 'utils/format';
import Button from 'components/UI/SecondaryButton';
import { ReactComponent as Icon } from 'assets/icons/history.svg';
import { ClientAgreementsTableItem } from '../types';

export function itemContent(_index: number, agreement: ClientAgreementsTableItem) {
  const { employee, employeeId, position, allocation, startDate, basis, amount, rate, dailyRate } = agreement;
  return (
    <>
      <TableCell>
        <Typography variant={'body2'} fontWeight={'600'}>
          {employee}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{position}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatPercent(allocation)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatDate(startDate)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatRate(basis, amount, rate, dailyRate)}</Typography>
      </TableCell>

      <TableCell>
        <Link component={RouterLink} to={`/agreements/${employeeId}`}>
          <Button>
            <Icon />
            See History
          </Button>
        </Link>
      </TableCell>
    </>
  );
}
