import { TableVirtuoso } from 'react-virtuoso';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import generateVirtuosoTableComponents from 'components/table/virtuoso.common';
import generateHeaderContent from 'components/table/header-content';
import { useAuth } from 'context/auth';
import { GetProjectResponse, useProjectGetOneQuery } from 'api/queries/projects/get-one';
import Wrap from 'components/table/Wrap';
import { itemContent } from './components/RowContent';
import { columns } from './config';
import { ClientAgreementsTableItem } from './types';

const VirtuozoTableComponents = generateVirtuosoTableComponents(columns);
const fixedHeaderContent = generateHeaderContent(columns);

export default function ClientAgreementsTable() {
  const { selectedProjectId } = useAuth();
  const { data } = useProjectGetOneQuery(selectedProjectId || '');
  const rows = useMemo(() => dataAdapter(data), [data]);

  return (
    <Wrap noData={rows.length === 0}>
      <TableVirtuoso
        fixedHeaderContent={fixedHeaderContent}
        itemContent={itemContent}
        data={rows}
        components={VirtuozoTableComponents}
      />
    </Wrap>
  );
}

function dataAdapter(data: GetProjectResponse | undefined): ClientAgreementsTableItem[] {
  if (!data) return [];

  return data?.agreements
    .filter(({ startDate, endDate }) => {
      const today = dayjs().startOf('day');
      const startedTodayOrBefore = dayjs(startDate).isSame(today, 'day') || dayjs(startDate).isBefore(today);
      const endsTodayOrAfter = dayjs(endDate).isSame(today, 'day') || dayjs(endDate).isAfter(today);

      return startedTodayOrBefore && endsTodayOrAfter;
    })
    .map((agreement) => {
      return {
        ...agreement,
        employee: [agreement.employee.firstName, agreement.employee.lastName].join(' '),
        position: agreement.employee.position,
      };
    });
}
