import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { UserData } from 'api/types';
import { API_USERS_ME } from 'constants/apiUrl';

async function getSelf() {
  const response = await axiosInstance.get<UserData>(API_USERS_ME);
  return response.data;
}

const queryKey = ['users-get-self'] as const;

export function useGetSelfQuery(enabled: boolean = true) {
  return useQuery({
    queryKey,
    queryFn: getSelf,
    enabled,
    refetchOnWindowFocus: false,
  });
}
