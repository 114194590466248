import React, { lazy } from 'react';
import { Box, Skeleton, Theme } from '@mui/material';
import { RichTextEditorProps } from './types';

const Component = lazy(() => import('./components/Instance'));

const loadingStateSx = (theme: Theme) => ({
  border: `1px solid ${theme.palette.divider}`,
});

const LoadingState = () => (
  <Box display="flex" flexDirection="column" gap={2} borderRadius={2} padding={2} sx={loadingStateSx}>
    <Skeleton variant={'rectangular'} width={'100%'} height={56} />
    <Skeleton variant={'rectangular'} width={'100%'} height={256} />
  </Box>
);

export default function RichTextEditor(props: RichTextEditorProps) {
  return (
    <React.Suspense fallback={<LoadingState />}>
      <Component {...props} />
    </React.Suspense>
  );
}
