import { AvatarProps, Avatar as MuiAvatar } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';
import { Wrap } from './styles';

type Props = PropsWithChildren<{
  onClick?: () => void;
}> &
  AvatarProps;

const Avatar = forwardRef<HTMLButtonElement, Props>(function Avatar({ onClick, children, ...props }, ref) {
  return (
    <Wrap ref={ref} onClick={() => onClick?.()} style={{ cursor: onClick ? 'pointer' : 'default' }}>
      <MuiAvatar sx={avatarStyle} {...props}>
        {children}
      </MuiAvatar>
    </Wrap>
  );
});

export default Avatar;

const avatarStyle = {
  width: '37px',
  height: '37px',
  fontSize: '14px',
};
