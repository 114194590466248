import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const forgotPasswordSchema = z.object({
  email: z.string().email('Wrong email format'),
});

export type ForgotPasswordFormSchema = z.infer<typeof forgotPasswordSchema>;

export default zodResolver(forgotPasswordSchema);

export const defaultValues: ForgotPasswordFormSchema = {
  email: '',
};
