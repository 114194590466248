import { useNavigationOptions } from 'components/AppHeader/options';
import { NavItem, Wrap } from './styles';

function AppSidebar() {
  const navOptions = useNavigationOptions();

  return (
    <Wrap>
      {navOptions.map(({ name, Icon, to }) => (
        <NavItem key={name} to={to}>
          <Icon alt="" />
          {name}
        </NavItem>
      ))}
    </Wrap>
  );
}

export default AppSidebar;
