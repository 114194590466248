import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import ReactGA from 'react-ga4';
import MaterialUiContainer from 'containers/MaterialUiContainer';
import router from 'navigation/routes';
import { mainTheme } from 'containers/MaterialUiContainer/mainTheme';
import AuthProvider from './context/auth';
import TanStackProvider from './context/tanstack';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string);

const App: FC = () => {
  return (
    <TanStackProvider>
      <MaterialUiContainer>
        <SnackbarProvider maxSnack={4} autoHideDuration={3000}>
          <ThemeProvider theme={mainTheme}>
            <AuthProvider>
              <RouterProvider router={router} />
            </AuthProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </MaterialUiContainer>
    </TanStackProvider>
  );
};

export default App;
