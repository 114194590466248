import { Box, TextField } from '@mui/material';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';
import { Navigate, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/auth';
import ButtonWithPreloader from 'components/UI/ButtonWithPreloader';
import { LoginParams } from 'api/mutations/auth/login';
import resolver, { AuthFormSchema, defaultValues } from './authSchema';
import { Form } from './styles';
import Layout from '../Layout';
import PasswordField from '../components/PasswordField';

export default function AuthPage() {
  const { onAuth, isAuthorised } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AuthFormSchema>({ resolver, defaultValues });
  const navigate = useNavigate();
  const location = useLocation();

  if (isAuthorised) {
    return <Navigate to="/" replace />;
  }

  const handleAuth = async (params: LoginParams) => {
    await onAuth(params);
    const from = location.state?.from || '/';
    navigate(from, { replace: true });
  };

  return (
    <Layout>
      <Form onSubmit={handleSubmit(handleAuth)}>
        <Box width={284}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            margin="dense"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message || ' '}
          />
          <PasswordField
            fullWidth
            label="Password"
            margin="dense"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message || ' '}
          />
          <ButtonWithPreloader fullWidth type="submit" variant="contained" loading={isSubmitting}>
            Log In
          </ButtonWithPreloader>
        </Box>
        <Box mt={1.5}>
          <Link component={RouterLink} to="/forgot-password">
            Forgot your password?
          </Link>
        </Box>
      </Form>
    </Layout>
  );
}
