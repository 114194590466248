import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { adaptiveColumnBoxSx } from 'components/common.styles';
import ClientAgreementsTable from 'components/table/client/Agreements';

export default function AgreementsPage() {
  return (
    <Box sx={adaptiveColumnBoxSx}>
      <Typography variant={'h6'} fontWeight={'800'} fontSize={'24px'}>
        Agreements
      </Typography>
      <ClientAgreementsTable />
    </Box>
  );
}
