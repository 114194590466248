import { Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';

function generateHeaderContent(columns: { itemKey: string; width: number; label: string }[]) {
  // it uses the function declaration way to enable default "display name" field for the generated component
  return function HeaderContent() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('tablet'));

    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.itemKey}
            variant="head"
            align={'left'}
            sx={{ width: column.width, bgcolor: 'primary.light' }}
          >
            <Typography variant={isDesktop ? 'body1' : 'body2'} fontWeight={'700'} color={'primary.contrastText'}>
              {column.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    );
  };
}

export default generateHeaderContent;
