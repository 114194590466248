import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_PROJECTS_SYNC } from 'constants/apiUrl';

export function useProjectsSync() {
  return useMutation({
    mutationKey: ['sync-projects'],
    mutationFn() {
      return axiosInstance.post(API_PROJECTS_SYNC);
    },
  });
}
