import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { adaptiveColumnBoxSx } from 'components/common.styles';
import ClientAgreementHistoryTable from 'components/table/client/AgreementHistory';
import { useAuth } from 'context/auth';
import { useAgreementsHistory } from 'api/queries/agreements/get-history';
import Loader from 'components/UI/Loader';
import BackLink from '../../../../components/UI/BackLink';

export default function AgreementHistoryPage() {
  const { agreementId = '' } = useParams();
  const { selectedProjectId } = useAuth();
  const { data, isLoading } = useAgreementsHistory(agreementId, selectedProjectId ?? '');

  if (isLoading) return <Loader />;

  const employee = data?.data[0]?.employee;

  return (
    <Box sx={adaptiveColumnBoxSx}>
      <Box display="flex" flexDirection="column" gap={2} alignItems={'flex-start'}>
        <BackLink to="/agreements">Back to Agreements List</BackLink>
        {employee && (
          <Typography variant={'h6'} fontWeight={'800'} fontSize={'24px'}>
            {employee.firstName + ' ' + employee.lastName}
          </Typography>
        )}
      </Box>
      <ClientAgreementHistoryTable />
    </Box>
  );
}
