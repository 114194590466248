import { Box, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from 'api/mutations/auth/reset-password';
import ButtonWithPreloader from 'components/UI/ButtonWithPreloader';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import resolver, { defaultValues, ForgotPasswordFormSchema } from './forgotPasswordSchema';
import { formWrapperSx } from '../common.styles';
import Layout from '../Layout';

export default function ForgotPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordFormSchema>({ resolver, defaultValues });

  const { mutateAsync: resetPassword } = useResetPasswordMutation();
  const navigate = useNavigate();

  const submit = (data: ForgotPasswordFormSchema) => {
    return resetPassword(data)
      .then(() => {
        enqueueSnackbar(
          'You will receive an email in your inbox with instructions if you are eligible to restore your account.',
          { variant: 'success' },
        );
        navigate('/confirm-password', { state: { email: data.email } });
      })
      .catch(handleAxiosError);
  };

  return (
    <Layout>
      <Box sx={formWrapperSx} component="form" onSubmit={handleSubmit(submit)}>
        <Typography fontSize={'20px'} mb={'16px'} fontWeight={900}>
          Restore account access
        </Typography>
        <Typography>Please provide your email to recover your account</Typography>
        <Box mt={2.5} width={284}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            margin="dense"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message || ' '}
          />
          <ButtonWithPreloader fullWidth type="submit" variant="contained" loading={isSubmitting}>
            Restore access
          </ButtonWithPreloader>
        </Box>
      </Box>
    </Layout>
  );
}
