import { useCallback, useMemo } from 'react';
import { USERS_PAGE_SIZE, useUsersGetManyQuery } from 'api/queries/users/get-many';
import { SuperAdminUsersTableItem } from '../types';

export default function useUsersTableData() {
  const queryMeta = useUsersGetManyQuery();

  const tableData = useMemo(() => {
    return (queryMeta.data?.pages || [])
      .flatMap((p) => p.data)
      .map<SuperAdminUsersTableItem>((user) => ({
        id: user.id,
        name: [user.firstName, user.lastName].filter(Boolean).join(' ') || 'N/A',
        email: user.email,
        position: user.position || 'N/A',
        projects: user.projects.map((p) => p.name),
        status: user.status,
        role: user.roles[0],
        manageLink: '',
      }));
  }, [queryMeta.data]);

  const isLoadingMore = queryMeta.isLoading || queryMeta.isFetchingNextPage;
  const canLoadMore =
    typeof queryMeta.data === 'object'
      ? queryMeta.data.pages.length * USERS_PAGE_SIZE < queryMeta.data.pages[0].count
      : true;

  const loadMore = useCallback(
    (isBottom: boolean) => {
      if (isBottom && !isLoadingMore && canLoadMore) {
        queryMeta.fetchNextPage();
      }
    },
    [queryMeta.fetchNextPage, isLoadingMore, canLoadMore],
  );

  return [tableData, queryMeta.isLoading || queryMeta.isFetchingNextPage, loadMore, queryMeta] as const;
}
