import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import SuperAdminProjectsTable from 'components/table/super-admin/Projects';
import { adaptiveColumnBoxSx } from 'components/common.styles';

export default function ProjectsPage() {
  return (
    <Box sx={adaptiveColumnBoxSx}>
      <Typography variant={'h6'} fontWeight={'800'} fontSize={'24px'}>
        Projects
      </Typography>
      <SuperAdminProjectsTable />
    </Box>
  );
}
