import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ProjectsIcon } from 'assets/icons/projects.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { ReactComponent as ProjectManagementIcon } from 'assets/icons/project-management.svg';
import { useAuth } from 'context/auth';
import { UserRole } from 'utils/enums';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as InvoicesIcon } from 'assets/icons/invoices.svg';
import { ReactComponent as AgreementsIcon } from 'assets/icons/agreements.svg';
import { ReactComponent as AvailableResourcesIcon } from 'assets/icons/available-resources.svg';
import { ReactComponent as TeamCalendarIcon } from 'assets/icons/team-calendar.svg';
import { ReactComponent as AdminIcon } from 'assets/icons/admin.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { RouterPath } from '../../navigation/types';

type NavOption = { name: string; to: RouterPath; Icon: any };

export const useNavigationOptions = () => {
  const { isAuthorised, role } = useAuth();
  const { pathname } = useLocation();

  return useMemo<NavOption[]>(() => {
    if (isAuthorised) {
      if (pathname.startsWith('/super-admin' as RouterPath)) {
        const options: NavOption[] = [{ name: 'Projects', to: '/super-admin/projects', Icon: ProjectsIcon }];
        if (role === UserRole.SUPER_ADMIN) {
          options.push({ name: 'Users', to: '/super-admin/users', Icon: UsersIcon });
        }
        return options;
      } else {
        return [
          { name: 'Dashboard', to: '/', Icon: DashboardIcon },
          { name: 'Invoices', to: '/invoices', Icon: InvoicesIcon },
          { name: 'Agreements', to: '/agreements', Icon: AgreementsIcon },
          { name: 'Team calendar', to: '/calendar', Icon: TeamCalendarIcon },
          { name: 'Available Resources', to: '/resources', Icon: AvailableResourcesIcon },
        ];
      }
    }

    return [];
  }, [isAuthorised, pathname, role]);
};

export const useProfileOptions = () => {
  const { role, onLogout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return useMemo(() => {
    const options: { name: string; onClick(): void; Icon: any }[] = [];

    if ([UserRole.SUPER_ADMIN, UserRole.PROJECT_ADMIN].includes(role!)) {
      if (pathname.includes('/super-admin')) {
        options.push({ name: 'Project Management', onClick: () => navigate('/'), Icon: ProjectManagementIcon });
      } else {
        options.push({ name: 'Admin', onClick: () => navigate('/super-admin/projects'), Icon: AdminIcon });
      }
    }

    // TODO: FIX LOGOUT LOGIC WITH REMOVING COOKIES
    options.push({
      name: 'Logout',
      onClick: onLogout,
      Icon: LogoutIcon,
    });

    return options;
  }, [role, pathname, navigate, onLogout]);
};
