import { TableFooter, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import MaterialTableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import MaterialTableRow from '@mui/material/TableRow';
import { FC, forwardRef } from 'react';
import { TableComponents } from 'react-virtuoso';
import EmptyBox from 'assets/empty-box.png';
import colors from 'constants/colors';

const Scroller = forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />);
Scroller.displayName = 'ProjectTable-Scroller';

const TableBody = forwardRef<HTMLTableSectionElement>((props, ref) => <MaterialTableBody {...props} ref={ref} />);
TableBody.displayName = 'ProjectTable-TableBody';

const TableRow: FC<any> = ({ item: _item, ...props }) => <MaterialTableRow {...props} />;

const generateVirtuosoTableComponents = (
  columns: any[],
): Pick<
  Required<TableComponents<any>>,
  'Scroller' | 'Table' | 'TableHead' | 'TableRow' | 'TableBody' | 'EmptyPlaceholder' | 'TableFoot'
> => ({
  Scroller,
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead,
  TableRow,
  TableBody,
  // eslint-disable-next-line react/display-name
  TableFoot: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableFooter ref={ref}>
      <MaterialTableRow>
        <TableCell {...props} colSpan={columns.length} />
      </MaterialTableRow>
    </TableFooter>
  )),
  EmptyPlaceholder: () => (
    <MaterialTableBody>
      <MaterialTableRow>
        <td align={'center'} height={'230px'} colSpan={columns.length}>
          <img src={EmptyBox} alt="empty box" width="142px" />
          <Typography variant={'body2'} fontWeight={'600'} color={colors.grey.dark}>
            There is no data
          </Typography>
        </td>
      </MaterialTableRow>
    </MaterialTableBody>
  ),
});

export default generateVirtuosoTableComponents;
