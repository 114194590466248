import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_AGREEMENTS_HISTORY } from 'constants/apiUrl';

export type AgreementBasis = 'Hourly' | 'Daily' | 'Monthly' | 'Flat';

type Employee = {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  position: string;
};

export type Agreement = {
  id: number;
  startDate: string;
  endDate: string;
  allocation: number;
  basis: AgreementBasis;
  rate: number;
  amount: number;
  dailyRate: number;
  rateCurrency: string;
  employee: Employee;
};

export type AfgreementsHistoryResponse = {
  page: number;
  pageSize: number;
  count: number;
  data: Agreement[];
};

export function useAgreementsHistory(employeeId: string, projectId: string) {
  return useQuery({
    queryKey: ['agreements-history', employeeId, projectId],
    queryFn: async () => {
      const response = await axiosInstance.get<AfgreementsHistoryResponse>(API_AGREEMENTS_HISTORY, {
        params: {
          page: 0,
          pageSize: 99999,
          orderBy: 'id',
          sortOrder: 'ASC',
          employeeId,
          projectId,
        },
      });

      return response.data;
    },
  });
}
