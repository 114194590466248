import { TableVirtuoso } from 'react-virtuoso';
import generateVirtuosoTableComponents from 'components/table/virtuoso.common';
import generateHeaderContent from 'components/table/header-content';
import Wrap from 'components/table/Wrap';
import { itemContent } from './components/RowContent';
import { columns } from './config';
import useUsersTableData from './hooks/table-data';
import LoadingFooter from '../../footer-content';

const VirtuosoTableComponents = generateVirtuosoTableComponents(columns);
const fixedHeaderContent = generateHeaderContent(columns);

export default function SuperAdminUsersTable() {
  const [filteredRows, isLoadingMore, loadMore] = useUsersTableData();

  return (
    <Wrap noData={filteredRows.length === 0}>
      <TableVirtuoso
        fixedHeaderContent={fixedHeaderContent}
        fixedFooterContent={isLoadingMore ? LoadingFooter : undefined}
        itemContent={itemContent}
        data={filteredRows}
        atBottomStateChange={loadMore}
        components={{
          ...VirtuosoTableComponents,
          EmptyPlaceholder: isLoadingMore ? undefined : VirtuosoTableComponents.EmptyPlaceholder,
        }}
      />
    </Wrap>
  );
}
