import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { UserData } from 'api/types';
import { API_USERS } from 'constants/apiUrl';
import axiosInstance from 'api/axios/instance';

export type UsersGetManyParams = {
  page: number;
};

export type UsersGetManyResponse = {
  page: number;
  pageSize: number;
  count: number;
  data: UserData[];
};

export const USERS_PAGE_SIZE = 20;

export const QUERY_KEY = 'users-get-many';

async function getManyUsers(params: UsersGetManyParams) {
  const result = await axiosInstance.get<UsersGetManyResponse>(API_USERS, {
    params: {
      ...params,
      pageSize: USERS_PAGE_SIZE,
    },
  });
  return result.data;
}

export function useUsersGetManyQuery() {
  const [queryKey, getNextPageParam] = useMemo(
    () =>
      [
        [QUERY_KEY],
        (lastPage: UsersGetManyResponse) => {
          if (lastPage.count > lastPage.pageSize * (lastPage.page + 1)) {
            return lastPage.page + 1;
          }
          return undefined;
        },
      ] as const,
    [],
  );

  return useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => getManyUsers({ page: pageParam }),
    getNextPageParam,
    initialPageParam: 0,
    refetchOnWindowFocus: false,
  });
}
