import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'context/auth';
import { UserRole } from 'utils/enums';
import Layout from 'components/Layout';

interface PrivateRouteProps {
  allowedRoles?: UserRole[];
}

const PrivateRoute = ({ allowedRoles }: PrivateRouteProps) => {
  const { role, isAuthorised } = useAuth();
  const location = useLocation();

  if (!isAuthorised) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles?.every((allowedRole) => allowedRole !== role)) {
    return <Navigate to="/" replace />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;
