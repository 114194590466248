import { Box, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import useToggle from 'hooks/toggle';
import RichTextEditor from 'components/complex/RichTextEditor';
import { useAuth } from 'context/auth';
import { UserRole } from 'utils/enums';
import { useProjectSetDescription } from 'api/mutations/projects/set-description';
import { getProjectOneQueryKey } from 'api/queries/projects/get-one';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import Button from 'components/UI/SecondaryButton';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { Section } from '../styles';

const defaultContent = `<div>
<h1>Hello!</h1>
<h2>Let's start to work with the editor!</h2>
<p>To start click on <b>"Edit"</b> button</p>
</div>`;

const PLACEHOLDER_TAG = '<p></p>';

type Props = {
  initialContent?: string;
  onUpdated(): void;
  projectId: string;
};

export default function ProjectUsefulRichInformation({ initialContent, onUpdated, projectId }: Props) {
  const [editMode, toggleEditMode] = useToggle(false);

  const queryClient = useQueryClient();
  const { mutateAsync: updateDescription, isPending: isUpdating } = useProjectSetDescription();

  const [editorContent, setEditorContent] = useState(initialContent || '');

  const edit = useCallback(() => {
    toggleEditMode();
  }, [toggleEditMode]);

  const { enqueueSnackbar } = useSnackbar();

  const save = useCallback(() => {
    updateDescription({
      projectId,
      description: editorContent,
    })
      .then(() => queryClient.refetchQueries({ queryKey: getProjectOneQueryKey(projectId) }))
      .then(() => {
        toggleEditMode();
        enqueueSnackbar('Changes have been saved!', { variant: 'success' });
        onUpdated();
      })
      .catch(handleAxiosError);
  }, [updateDescription, projectId, editorContent, queryClient, toggleEditMode, enqueueSnackbar, onUpdated]);

  const { role } = useAuth();

  return (
    <Section>
      <Box display="flex" flexDirection="column" gap={2} minHeight="256px">
        <Box display="flex" gap={2} alignItems="center">
          <Typography variant={'h6'} fontWeight={'600'} mr={'auto'}>
            Useful links & info
          </Typography>
          {!!role && role !== UserRole.CLIENT && (
            <>
              {editMode ? (
                <>
                  <Button onClick={toggleEditMode}>Cancel</Button>
                  <Button loading={isUpdating} onClick={save}>
                    Save
                  </Button>
                </>
              ) : (
                <Button onClick={edit}>
                  <PenIcon /> Edit
                </Button>
              )}
            </>
          )}
        </Box>

        {editMode ? (
          <RichTextEditor initialContent={initialContent} onUpdate={setEditorContent} />
        ) : (
          <Box
            dangerouslySetInnerHTML={{
              __html: initialContent && initialContent !== PLACEHOLDER_TAG ? initialContent : defaultContent,
            }}
          />
        )}
      </Box>
    </Section>
  );
}
