import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { formatCurrency, formatDate } from 'utils/format';
import Chip from 'components/table/client/Invoices/components/Chip';
import OpenButton from './OpenButton';
import { ClientInvoicesTableItem } from '../types';

export function itemContent(
  _index: number,
  { id, status, invoiceNumber, period, dateSent, dueDate, paidDate, amount }: ClientInvoicesTableItem,
) {
  return (
    <>
      <TableCell>
        <Chip status={status} />
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{invoiceNumber}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{period}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatDate(dateSent)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatDate(dueDate)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatDate(paidDate)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatCurrency(amount)}</Typography>
      </TableCell>

      <TableCell>
        <OpenButton invoiceId={id} />
      </TableCell>
    </>
  );
}
