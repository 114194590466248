import { SuperAdminUsersColumnData } from './types';

export const columns: SuperAdminUsersColumnData[] = [
  { width: 120, label: 'User name', itemKey: 'name' },
  { width: 160, label: 'User email', itemKey: 'email' },
  { width: 100, label: 'User position', itemKey: 'position' },
  { width: 150, label: 'Projects', itemKey: 'projects' },
  { width: 120, label: 'Role', itemKey: 'role' },
  { width: 100, label: 'Status', itemKey: 'status' },
  { width: 80, label: '', itemKey: 'manageLink' },
];
