import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { GetProjectResponse } from 'api/queries/projects/get-one';
import { InformationWrap, Section } from '../styles';

export default function ProjectInformation({ data }: { data?: GetProjectResponse }) {
  return (
    <Section>
      <Typography variant={'h6'} fontWeight="800" mb="24px">
        Project information
      </Typography>

      {data ? (
        <InformationWrap>
          <Box display={'flex'} columnGap={4} rowGap={1} flexWrap={'wrap'}>
            <Typography width={128} fontWeight={'600'}>
              Project manager
            </Typography>
            <Typography sx={{ wordBreak: 'break-word' }}>
              {[data.projectManager?.firstName, data.projectManager?.lastName].filter(Boolean).join(' ') || 'N/A'}
            </Typography>
          </Box>
          <Box display={'flex'} columnGap={4} rowGap={1} flexWrap={'wrap'}>
            <Typography width={128} fontWeight={'600'}>
              Start Date
            </Typography>
            <Typography>{dayjs(data.startDate).format('MM-DD-YYYY')}</Typography>
          </Box>
        </InformationWrap>
      ) : (
        <CircularProgress size={48} />
      )}
    </Section>
  );
}
