import { Button, Typography } from '@mui/material';
import { Fragment, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { adaptiveColumnBoxSx } from 'components/common.styles';
import SuperAdminProjectUsersTable from 'components/table/super-admin/ProjectUsers';
import useToggle from 'hooks/toggle';
import BackLink from 'components/UI/BackLink';
import { useProjectGetUsersQuery } from 'api/queries/projects/get-project-users';

const AddUserModal = lazy(() => import('components/modal/super-admin/AddProjectUser'));

export default function ProjectsManagePage() {
  const { projectId = '' } = useParams();
  const { data, isLoading } = useProjectGetUsersQuery(projectId);

  const [addModalVisible, toggleAddModalVisible] = useToggle(false);

  return (
    <Box sx={adaptiveColumnBoxSx}>
      <Box display="flex" flexDirection="column" gap={2}>
        <BackLink to="/super-admin/projects">Back to Projects List</BackLink>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
          <Typography variant={'h6'} fontWeight={'800'} fontSize={'24px'}>
            {data?.name || 'Loading...'} - Users management
          </Typography>
          {projectId && (
            <Button variant={'contained'} onClick={toggleAddModalVisible} startIcon={<AddIcon />}>
              Add user
            </Button>
          )}
        </Box>
      </Box>
      <SuperAdminProjectUsersTable data={data} isLoading={isLoading} />

      {addModalVisible && projectId && (
        <Suspense fallback={<Fragment />}>
          <AddUserModal onClose={toggleAddModalVisible} projectId={projectId} />
        </Suspense>
      )}
    </Box>
  );
}
