import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_AUTH_COMPLETE_SIGNUP } from 'constants/apiUrl';

export type ConfirmPasswordParams = {
  email: string | null;
  verificationCode: string | null;
  password: string;
};

async function completeSignup(params: ConfirmPasswordParams) {
  await axiosInstance.post(API_AUTH_COMPLETE_SIGNUP, JSON.stringify(params));
}

const mutationKey = ['complete-signup'] as const;

export function useCompleteSignupMutation() {
  return useMutation({
    mutationKey,
    mutationFn: completeSignup,
  });
}
