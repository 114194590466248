import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_AUTH_CONFIRM_PASSWORD } from 'constants/apiUrl';

export type ConfirmPasswordParams = {
  email: string;
  verificationCode: string;
  password: string;
};

async function confirmPassword(params: ConfirmPasswordParams) {
  await axiosInstance.post<ConfirmPasswordParams>(API_AUTH_CONFIRM_PASSWORD, JSON.stringify(params));
}

const mutationKey = ['confirm-password'] as const;

export function useConfirmPasswordMutation() {
  return useMutation({
    mutationKey,
    mutationFn: confirmPassword,
  });
}
