import { FC, PropsWithChildren } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { mainTheme } from './mainTheme';

const MaterialUiContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MaterialUiContainer;
