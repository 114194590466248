import { UserAccessStatus, UserRole } from './enums';

export const userRoleLabelMapping: Record<UserRole, string> = {
  [UserRole.CLIENT]: 'Client',
  [UserRole.PROJECT_ADMIN]: 'Project Admin',
  [UserRole.SUPER_ADMIN]: 'Super Admin',
};

export const userAccessStatusLabelMapping: Record<UserAccessStatus, string> = {
  [UserAccessStatus.ACTIVE]: 'Active',
  [UserAccessStatus.INVITED]: 'Invited',
};
