import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_AUTH_RESET_PASSWORD } from 'constants/apiUrl';

export type ResetPasswordParams = {
  email: string;
};

async function resetPassword(params: ResetPasswordParams) {
  await axiosInstance.post<ResetPasswordParams>(API_AUTH_RESET_PASSWORD, JSON.stringify(params));
}

const mutationKey = ['reset-password'] as const;

export function useResetPasswordMutation() {
  return useMutation({
    mutationKey,
    mutationFn: resetPassword,
  });
}
