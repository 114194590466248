import styled from '@emotion/styled';

export const Wrap = styled.div`
  height: calc(100vh - 67px);
  display: flex;
`;

export const ContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`;
