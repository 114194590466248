import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useAuth } from 'context/auth';
import axiosInstance from 'api/axios/instance';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import Button from 'components/UI/SecondaryButton';
import { ReactComponent as Icon } from 'assets/icons/open-document.svg';

interface OpenButtonProps {
  invoiceId: number;
}

const OpenButton = ({ invoiceId }: OpenButtonProps) => {
  const { selectedProjectId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleOpenInvoice = async () => {
    setLoading(true);
    const endpoint = `/projects/${selectedProjectId}/download-invoice/${invoiceId}.pdf`;
    axiosInstance
      .get(endpoint, {
        responseType: 'blob',
        headers: { Accept: 'application/pdf' },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      })
      .catch(handleAxiosError)
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button onClick={() => handleOpenInvoice()} loading={loading}>
      <Icon />
      Open Invoice
    </Button>
  );
};

export default OpenButton;
