import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Fragment } from 'react';
import ProjectFileList from './sections/FileList';
import ProjectInformation from './sections/Information';
import ProjectUsefulRichInformation from './sections/UsefulRichInformation';
import AdditionalInformation from './sections/AdditionalInformation';
import useToggle from '../../../../hooks/toggle';
import { useAuth } from '../../../../context/auth';
import { useProjectGetOneQuery } from '../../../../api/queries/projects/get-one';

const layoutSx: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  columnGap: '16px',
  rowGap: 4,
  //
  padding: 2,
  paddingBottom: 24,
  [theme.breakpoints.up('tablet')]: {
    padding: '16px 24px',
    paddingBottom: 24,
  },
});

export default function DashboardPage() {
  const { selectedProjectId } = useAuth();
  const { data } = useProjectGetOneQuery(selectedProjectId || '');

  const [renderEditor, toggleRenderEditor] = useToggle(false);

  return (
    <Box sx={layoutSx}>
      <Box display={'flex'} flexDirection={'column'} gap="16px" flex={1} minWidth={328}>
        <Typography variant="h1" fontSize="24px" fontWeight="800">
          Dashboard
        </Typography>
        <ProjectInformation data={data} />
        <ProjectFileList />
        {!!data && (
          <Fragment key={+renderEditor}>
            <ProjectUsefulRichInformation
              key={selectedProjectId || 1}
              initialContent={data.description}
              onUpdated={toggleRenderEditor}
              projectId={selectedProjectId || ''}
            />
          </Fragment>
        )}
      </Box>
      <AdditionalInformation />
    </Box>
  );
}
