import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import axiosInstance from 'api/axios/instance';
import { API_USERS_RESEND_INVITE } from 'constants/apiUrl';

export type UserResendInviteParams = {
  userId: string;
};

async function resendInviteUser(params: UserResendInviteParams) {
  await axiosInstance.post(API_USERS_RESEND_INVITE, JSON.stringify(params));
}

export function useUserResendInviteMutation(params: UserResendInviteParams) {
  const [mutationKey, mutationFn] = useMemo(
    () => [['resend-invite-user', params.userId], () => resendInviteUser(params)] as const,
    [params],
  );

  return useMutation({
    mutationKey,
    mutationFn,
  });
}
