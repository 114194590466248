import styled from '@emotion/styled';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;

export const ImageLeft = styled.img`
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ImageRigth = styled.img`
  z-index: -1;
  position: fixed;
  bottom: 0;
  right: 0;
`;

export const Form = styled.form`
  width: 284px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 176px;
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
`;
