import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Fragment, lazy, Suspense } from 'react';
import { adaptiveColumnBoxSx } from 'components/common.styles';
import SuperAdminUsersTable from 'components/table/super-admin/Users';
import useToggle from 'hooks/toggle';

const AddUserModal = lazy(() => import('components/modal/super-admin/AddSystemUser'));

export default function UsersPage() {
  const [addModalVisible, toggleAddModalVisible] = useToggle(false);

  return (
    <Box sx={adaptiveColumnBoxSx}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
        <Typography variant={'h6'} fontWeight={'800'} fontSize={'24px'}>
          Users
        </Typography>
        <Button variant={'contained'} onClick={toggleAddModalVisible} startIcon={<AddIcon />}>
          Add user
        </Button>
      </Box>
      <SuperAdminUsersTable />

      {addModalVisible && (
        <Suspense fallback={<Fragment />}>
          <AddUserModal onClose={toggleAddModalVisible} />
        </Suspense>
      )}
    </Box>
  );
}
