import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import colors from 'constants/colors';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  border-right: 1px solid ${colors.slate.main};
  padding-top: 16px;

  @media screen and (max-width: 515px) {
    display: none;
  }
`;

type NavItemProps = {
  active?: boolean;
};

export const NavItem = styled(NavLink)<NavItemProps>`
  display: block;
  padding: 12px 8px 12px 24px;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.slate[950]};
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    color: ${colors.purple.main};
    svg > path {
      fill: ${colors.purple.main};
    }
  }

  &.active {
    background-color: ${colors.grey.light};
    color: ${colors.purple.main};
    &:after {
      content: '';
      position: absolute;
      top: 10px;
      right: 0;
      width: 4px;
      height: 26px;
      background-color: ${colors.purple.main};
      border-radius: 20px;
    }

    svg > path {
      fill: ${colors.purple.main};
    }
  }
`;
