import { List, ListItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useState } from 'react';
import axiosInstance from 'api/axios/instance';
import { Document, useProjectGetOneQuery } from 'api/queries/projects/get-one';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { useAuth } from 'context/auth';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import Button from 'components/UI/SecondaryButton';
import { Section } from '../styles';
import { ReactComponent as Icon } from 'assets/icons/download.svg';

export default function ProjectFileList() {
  const [loadingIds, setLoadingIds] = useState<Document['id'][]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedProjectId } = useAuth();
  const { data } = useProjectGetOneQuery(selectedProjectId || '');
  const { documents } = data ?? {};

  const handleDownloadDocument = async (doc: Document) => {
    setLoadingIds((ids) => [...ids, doc.id]);
    const endpoint = `/projects/download-document/${doc.id}`;
    axiosInstance
      .get(endpoint, {
        responseType: 'blob',
        headers: { Accept: doc.mimeType },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: doc.mimeType });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      })
      .catch(handleAxiosError)
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'warning' });
      })
      .finally(() => {
        setLoadingIds((ids) => ids.filter((id) => id !== doc.id));
      });
  };

  return (
    <Section>
      <Typography variant={'h6'} fontWeight="800" mb="24px">
        Project files
      </Typography>
      {documents?.length ? (
        <List>
          {documents.map((doc) => (
            <ListItem key={doc.name} sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <FileIcon />
              <Typography sx={{ marginRight: 'auto' }}>{doc.name}</Typography>
              <Button loading={loadingIds.includes(doc.id)} onClick={() => handleDownloadDocument(doc)}>
                <Icon />
                Preview
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant={'body1'}>No files</Typography>
      )}
    </Section>
  );
}
