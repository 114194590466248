import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = () => {
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
