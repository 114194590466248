import { createBrowserRouter } from 'react-router-dom';
import { UserRole } from 'utils/enums';
import Home from './pages/Home';
import DashboardPage from './pages/client/Dashboard/component';
import InvoicesPage from './pages/client/Invoices/component';
import AgreementsPage from './pages/client/Agreements/component';
import AgreementHistoryPage from './pages/client/AgreementHistory/component';
import CalendarPage from './pages/client/Calendar';
import ResourcesPage from './pages/client/Resources/component';
import AuthPage from './pages/login/Auth/component';
import ForgotPasswordPage from './pages/login/ForgotPassword/component';
import InvitePage from './pages/login/Invite/component';
import ProjectsPage from './pages/super-admin/Projects/component';
import ProjectsManagePage from './pages/super-admin/ProjectsManage/component';
import UsersPage from './pages/super-admin/Users/component';
import PrivateRoute from './PrivateRoute';
import ConfirmPasswordPage from './pages/login/ConfirmPassword/component';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    children: [
      // public routes
      { path: '/login', element: <AuthPage /> },
      { path: '/invite', element: <InvitePage /> },
      { path: '/forgot-password', element: <ForgotPasswordPage /> },
      { path: '/confirm-password', element: <ConfirmPasswordPage /> },
      // private routes
      {
        element: <PrivateRoute />,
        children: [
          { index: true, element: <DashboardPage /> },
          { path: '/invoices', element: <InvoicesPage /> },
          { path: '/agreements', element: <AgreementsPage /> },
          { path: '/agreements/:agreementId', element: <AgreementHistoryPage /> },
          // TODO: Connect to BE
          { path: '/calendar', element: <CalendarPage /> },
          { path: '/resources', element: <ResourcesPage /> },
        ],
      },
      {
        element: <PrivateRoute allowedRoles={[UserRole.PROJECT_ADMIN, UserRole.SUPER_ADMIN]} />,
        children: [
          { path: '/super-admin/projects', element: <ProjectsPage /> },
          { path: '/super-admin/projects/:projectId/manage', element: <ProjectsManagePage /> },
        ],
      },
      {
        element: <PrivateRoute allowedRoles={[UserRole.SUPER_ADMIN]} />,
        children: [{ path: '/super-admin/users', element: <UsersPage /> }],
      },
    ],
  },
]);

export default router;
