import { Box, Link, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { ConfirmPasswordParams, useCompleteSignupMutation } from 'api/mutations/users/complete-signup';
import ButtonWithPreloader from 'components/UI/ButtonWithPreloader';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import { useAuth } from 'context/auth';
import resolver, { defaultValues, InviteFormSchema } from './inviteSchema';
import { formWrapperSx } from '../common.styles';
import PasswordField from '../components/PasswordField';
import Layout from '../Layout';

export default function InvitePage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InviteFormSchema>({ resolver, defaultValues });
  const { onLogout, isAuthorised } = useAuth();
  const { mutateAsync: confirmPassword, isPending: isLoading } = useCompleteSignupMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isAuthorised) return;

    (async () => {
      if (!isAuthorised) return;

      try {
        await onLogout({ redirectToRoot: false });
        enqueueSnackbar('You have been logged out!', { variant: 'info' });
      } catch (err) {
        navigate('/');
        enqueueSnackbar('Failed to log out!', {
          variant: 'warning',
          autoHideDuration: 5000,
        });
      }
    })();
  }, [isAuthorised]);

  const submit = (data: InviteFormSchema) => {
    const params: ConfirmPasswordParams = {
      email: '',
      verificationCode: '',
      password: data.password,
    };

    try {
      const jwt = jwtDecode(searchParams.get('data') || '', { header: true }) as Record<string, string>;

      params.email = jwt.email || searchParams.get('email');
      params.verificationCode = jwt.code || searchParams.get('verificationCode');
    } catch (err) {
      console.error(err);
    }

    return confirmPassword(params)
      .then(() => {
        enqueueSnackbar('Password has been confirmed!', { variant: 'success' });
        navigate('/');
      })
      .catch(handleAxiosError);
  };

  return (
    <Layout>
      <Box sx={formWrapperSx} component="form" onSubmit={handleSubmit(submit)}>
        <Typography fontSize={'20px'} fontWeight={900}>
          Create Password
        </Typography>
        <Box mt={2.5} width={284}>
          <PasswordField
            fullWidth
            label="Password"
            margin="dense"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message ? String(errors.password?.message) : ' '}
          />
          <PasswordField
            fullWidth
            label="Repeat password"
            margin="dense"
            {...register('repeatPassword')}
            error={!!errors.repeatPassword}
            helperText={errors.repeatPassword?.message ? String(errors.repeatPassword?.message) : ' '}
          />
        </Box>
        <Box mt={2.5}>
          <ButtonWithPreloader fullWidth type="submit" variant="contained" loading={isLoading}>
            Create Password
          </ButtonWithPreloader>
        </Box>
        <Link mt={1.5} component={RouterLink} to="/">
          Already have an account? Log In
        </Link>
      </Box>
    </Layout>
  );
}
