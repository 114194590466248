import LogoImage from 'assets/logo.png';
import ProjectSelect from './ProjectSelect';
import { InnerWrap, LogoContainer, Wrap } from './styles';
import Profile from './Profile';
import MobileNavMenu from './MobileNavMenu';

export default function AppHeader() {
  const isMobile = window.innerWidth < 515;
  return (
    <>
      <Wrap>
        {isMobile ? (
          <MobileNavMenu />
        ) : (
          <LogoContainer>
            <img alt="GENIUSEE" src={LogoImage} width={138} />
          </LogoContainer>
        )}
        <InnerWrap>
          <ProjectSelect />
          <Profile />
        </InnerWrap>
      </Wrap>
    </>
  );
}
