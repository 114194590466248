import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import Button from 'components/UI/SecondaryButton';
import { SuperAdminProjectTableItem } from '../types';

export function itemContent(_index: number, { id, name, manager, startDate }: SuperAdminProjectTableItem) {
  return (
    <>
      <TableCell>
        <Typography variant={'body2'} fontWeight={'700'}>
          {name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
          <span>{manager.name}</span>
          <br />
          <a href={`mailto:${manager.email}`}>{manager.email}</a>
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{dayjs(startDate).format('MMM DD, YYYY')}</Typography>
      </TableCell>

      <TableCell>
        <NavLink to={`/super-admin/projects/${id}/manage`} style={{ textDecoration: 'none' }}>
          <Button>Manage access</Button>
        </NavLink>
      </TableCell>
    </>
  );
}
