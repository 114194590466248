import { Autocomplete, AutocompleteRenderInputParams, InputAdornment, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useMemo } from 'react';
import useMyProjectsSelector from 'api/selectors/my-projects';
import { SelectOption } from 'utils/types';
import { useAuth } from 'context/auth';

interface ProjectSelectProps {
  onItemSelected?(): void;
}

const getOptionLabel = (opt: SelectOption) => opt.label;
const isOptionEqualToValue = (opt: SelectOption, val?: SelectOption) => opt.id === val?.id;

export default function ProjectSelect({ onItemSelected = () => {} }: ProjectSelectProps) {
  const { selectedProjectId, onProjectChange } = useAuth();
  const [projects, isLoading, _, setSearchToken] = useMyProjectsSelector();

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label="Select Project"
        onChange={(e) => setSearchToken(e.target.value)}
        InputProps={{
          ...params.InputProps,
          endAdornment: isLoading ? (
            <InputAdornment position="start">
              <CircularProgress size={24} />
            </InputAdornment>
          ) : (
            params.InputProps.endAdornment
          ),
        }}
      />
    ),
    [isLoading, setSearchToken],
  );

  const options = useMemo(() => projects.map((p) => ({ id: String(p.id), label: p.name })), [projects]);

  const onSelectProject = useCallback(
    (_: unknown, option?: SelectOption) => {
      if (option) {
        onProjectChange(option.id);
        onItemSelected();
      }
    },
    [onItemSelected, onProjectChange],
  );

  return (
    <Autocomplete
      sx={{ width: '225px' }}
      size="small"
      disableClearable
      options={options}
      value={options.find((o) => o.id === selectedProjectId)}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onSelectProject}
      renderInput={renderInput}
    />
  );
}
