import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { formatPercent, formatPeriod, formatRate } from 'utils/format';
import { ClientAgreementHistoryTableItem } from '../types';

export function itemContent(
  _index: number,
  {
    allocation,
    startDate,
    endDate,
    rate,
    dailyRate,
    amount,
    basis,
    hideEndDate,
    employee,
  }: ClientAgreementHistoryTableItem,
) {
  return (
    <>
      <TableCell>
        <Typography variant={'body2'}>{formatPercent(allocation)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{employee.position}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{formatPeriod(startDate, endDate, { hideEndDate })}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'}>{formatRate(basis, amount, rate, dailyRate)}</Typography>
      </TableCell>
    </>
  );
}
