import '@fontsource/inter';
import '@fontsource/inter/400.css';
import { createTheme, PaletteColorOptions } from '@mui/material/styles';
import colors from 'constants/colors';
import '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
  interface Palette {
    custom?: PaletteColorOptions;

    calendar: {
      indicator: {
        orange: string;
        yellow: string;
        blue: string;
        green: string;
        grey: string;
      };
      header: {
        bg_weekday: string;
        bg_weekend: string;
      };
    };
  }
  interface PaletteOptions {
    custom?: PaletteColorOptions;
    slate?: PaletteColorOptions;
    calendar: {
      indicator: {
        orange: string;
        yellow: string;
        blue: string;
        green: string;
        grey: string;
      };
      header: {
        bg_weekday: string;
        bg_weekend: string;
      };
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    custom: true;
  }
}

export const mainTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 720,
      desktop: 1280,
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
  palette: {
    mode: 'light',
    custom: {
      main: '#e6e6e6',
    },
    primary: {
      main: '#8585FF',
    },
    slate: {
      main: '#e2e8f0',
    },
    // Colors was got from the Tailwind theme
    // https://tailwindcss.com/docs/customizing-colors#default-color-palette
    calendar: {
      indicator: {
        orange: '#ea580c',
        yellow: '#facc15',
        green: '#16a34a',
        blue: '#3b82f6',
        grey: '#a1a1aa',
      },
      header: {
        bg_weekday: '#dbeafe',
        bg_weekend: '#fed7aa',
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '12px 20px',
          borderRadius: '8px',
          fontSize: '16px',
          textTransform: 'unset',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: colors.purple.dark,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.blue.main,
          textDecoration: 'none',
          '&:hover': {
            borderBottom: `1px solid ${colors.blue.main}`,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiInputBase-root': {
            borderRadius: '6px',
          },
          '.MuiFormLabel-root': {
            color: '#64748B',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F3F3FE',
          border: `1px solid ${colors.purple.main}`,
          color: colors.purple.main,
          fontWeight: 800,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 6px 14.2px 0px rgba(2, 6, 23, 0.08)',
          border: `1px solid ${colors.slate.main}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: colors.slate.main,
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        transitionDuration: 0,
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root.MuiTableCell-head': {
            backgroundColor: colors.black.main,
          },
          '.MuiTypography-root': {
            color: colors.grey.light,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: colors.slate.main,
          color: colors.grey.dark,
          padding: '12px',
        },
      },
    },
  },
});
