import { Box, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirmPasswordMutation } from 'api/mutations/auth/confirm-password';
import ButtonWithPreloader from 'components/UI/ButtonWithPreloader';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import resolver, { ConfirmPasswordFormSchema, defaultValues } from './confirmPasswordSchema';
import { formWrapperSx } from '../common.styles';
import Layout from '../Layout';
import PasswordField from '../components/PasswordField';

export default function ConfirmPasswordPage() {
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ConfirmPasswordFormSchema>({ resolver, defaultValues });

  const { mutateAsync: confirmPassword } = useConfirmPasswordMutation();
  const navigate = useNavigate();

  const submit = (data: ConfirmPasswordFormSchema) => {
    return confirmPassword({ email: state.email, verificationCode: data.verificationCode, password: data.password })
      .then(() => {
        enqueueSnackbar('Password changed successfully', { variant: 'success' });
        navigate('/login');
      })
      .catch(handleAxiosError);
  };

  return (
    <Layout>
      <Box sx={formWrapperSx} component="form" onSubmit={handleSubmit(submit)}>
        <Typography fontSize={'20px'} mb={'16px'} fontWeight={900}>
          Restore account access
        </Typography>
        <Typography>Please enter verification code and new password to recover your account</Typography>
        <Box mt={2.5} width={284}>
          <TextField
            fullWidth
            label="Verification code"
            margin="dense"
            {...register('verificationCode')}
            error={!!errors.verificationCode}
            helperText={errors.verificationCode?.message || ' '}
          />
          <PasswordField
            fullWidth
            label="Password"
            margin="dense"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message ? String(errors.password?.message) : ' '}
          />
          <PasswordField
            fullWidth
            label="Repeat password"
            margin="dense"
            {...register('repeatPassword')}
            error={!!errors.repeatPassword}
            helperText={errors.repeatPassword?.message ? String(errors.repeatPassword?.message) : ' '}
          />
          <ButtonWithPreloader fullWidth type="submit" variant="contained" loading={isSubmitting}>
            Restore access
          </ButtonWithPreloader>
        </Box>
      </Box>
    </Layout>
  );
}
