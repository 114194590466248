import React from 'react';
import ReactDOM from 'react-dom/client';

import dayjs from 'dayjs';
import dayjs_localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import dayjs_isLeapYearPlugin from 'dayjs/plugin/isLeapYear';
import dayjs_dayOfYearPlugin from 'dayjs/plugin/dayOfYear';
import dayjs_weekOfYearPlugin from 'dayjs/plugin/weekOfYear';
import dayjs_isoWeekPlugin from 'dayjs/plugin/isoWeek';
import dayjs_isoWeeksInYearPlugin from 'dayjs/plugin/isoWeeksInYear';
import App from './App';

dayjs.extend(dayjs_localizedFormatPlugin);
dayjs.extend(dayjs_isLeapYearPlugin);
dayjs.extend(dayjs_dayOfYearPlugin);
dayjs.extend(dayjs_weekOfYearPlugin);
dayjs.extend(dayjs_isoWeekPlugin);
dayjs.extend(dayjs_isoWeeksInYearPlugin);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
