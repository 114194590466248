import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_AUTH_LOG_OUT } from 'constants/apiUrl';

async function logout() {
  await axiosInstance.post(API_AUTH_LOG_OUT);
}

const mutationKey = ['logout'] as const;

export function useLogoutMutation() {
  return useMutation({
    mutationKey,
    mutationFn: logout,
  });
}
