import { PropsWithChildren } from 'react';
import Back1 from 'assets/auth-back-left.svg';
import Back2 from 'assets/auth-back-right.svg';
import LogoImage from 'assets/logo.png';
import { ImageLeft, ImageRigth, Logo, Wrap } from './Auth/styles';

function Layout({ children }: PropsWithChildren) {
  return (
    <Wrap>
      <ImageLeft src={Back1} alt="back" />
      <ImageRigth src={Back2} alt="back" />
      <Logo src={LogoImage} alt="logo" />
      {children}
    </Wrap>
  );
}

export default Layout;
