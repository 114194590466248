import { ClientAgreementsColumnData } from './types';

export const columns: ClientAgreementsColumnData[] = [
  { width: 150, label: 'Employee', itemKey: 'employee' },
  { width: 120, label: 'Position', itemKey: 'position' },
  { width: 90, label: 'Allocation', itemKey: 'allocation' },
  { width: 150, label: 'Start Date', itemKey: 'startDate' },
  { width: 120, label: 'Current rate', itemKey: 'rate' },
  { width: 120, label: '', itemKey: 'historyLink' },
];
