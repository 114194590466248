import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { Agreement } from '../projects/get-one';

type Data = {
  agreements: Agreement[];
  name: string;
  description: string;
  projectManager: {
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
  };
};

export function useGetAbailableResources() {
  return useQuery({
    queryKey: ['available-resources'],
    queryFn: async () => {
      const response = await axiosInstance.get<Data>('projects/bench');
      return response.data;
    },
  });
}
