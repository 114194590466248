import { useMemo, useState } from 'react';
import useDebouncedValue from 'hooks/debounce';
import { UserRole } from 'utils/enums';
import { useProjectsGetManyQuery } from 'api/queries/projects/get-many';
import { useGetSelfQuery } from 'api/queries/users/get-self';

export default function useMyProjectsSelector(enabled = true) {
  const { data, isLoading: isLoadingUser } = useGetSelfQuery(enabled);

  const [searchToken, setSearchToken] = useState('');
  const debouncedToken = useDebouncedValue(searchToken.toLowerCase().trim());

  const isSuperAdmin = Boolean(data?.roles?.includes(UserRole.SUPER_ADMIN));

  const { data: projectsPages, isLoading: isLoadingProjects } = useProjectsGetManyQuery(
    { query: debouncedToken },
    isSuperAdmin && enabled,
  );

  const filteredProjects = useMemo(() => {
    const projects = isSuperAdmin ? (projectsPages?.pages || []).flatMap((page) => page.data) : data?.projects || [];

    return projects.filter((project) => project.name.toLowerCase().includes(debouncedToken));
  }, [isSuperAdmin, data, debouncedToken, projectsPages]);

  const isLoading = isLoadingUser || isLoadingProjects;

  return [filteredProjects, isLoading, searchToken, setSearchToken] as const;
}
