import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function PageLoader() {
  return (
    <Box width={'100%'} padding={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress size={64} />
    </Box>
  );
}
