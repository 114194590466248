import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { formatDate, formatPercent } from 'utils/format';
import { ClientResourcesTableItem } from '../types';

export function itemContent(
  _index: number,
  { name, position, availableFromDate, allocation }: ClientResourcesTableItem,
) {
  return (
    <>
      <TableCell>
        <Typography variant={'body2'} fontWeight={'600'}>
          {name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant={'body2'} fontWeight={'600'}>
          {position}
        </Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant={'body2'}>{seniority}</Typography>
      </TableCell> */}

      <TableCell>
        <Typography variant={'body2'}>{formatDate(availableFromDate)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant={'body2'}>{formatPercent(allocation)}</Typography>
      </TableCell>
    </>
  );
}
