import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { LoginResponse } from 'api/types';
import { API_AUTH_LOG_IN } from 'constants/apiUrl';

export type LoginParams = {
  email: string;
  password: string;
};

async function login(params: LoginParams) {
  const response = await axiosInstance.post<LoginResponse>(API_AUTH_LOG_IN, JSON.stringify(params));
  return response.data;
}

const mutationKey = ['login'] as const;

export function useLoginMutation() {
  return useMutation({
    mutationKey,
    mutationFn: login,
  });
}
