import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, Ref, useState } from 'react';

function PasswordField({ InputProps, ...rest }: TextFieldProps, ref: Ref<HTMLInputElement>) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);

  return (
    <TextField
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClick}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...rest}
    />
  );
}

export default forwardRef(PasswordField);
