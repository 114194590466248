import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function SearchInput() {
  const [params, setSearchParams] = useSearchParams();
  const query = params.get('q') || '';

  const [searchToken, setSearchToken] = useState(params.get('q') || '');

  useEffect(() => {
    const timeout = setTimeout(() => setSearchParams(searchToken ? { q: searchToken } : {}), 400);
    return () => clearTimeout(timeout);
  }, [searchToken, setSearchParams]);

  const ClearInputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position={'end'}>
          <IconButton
            onClick={() => {
              setSearchToken('');
              setSearchParams({});
            }}
          >
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [setSearchParams],
  );

  return (
    <TextField
      value={searchToken}
      onChange={(e) => setSearchToken(e.target.value)}
      label={'Search for projects'}
      placeholder={'Project name'}
      sx={{ maxWidth: '428px' }}
      InputProps={query ? ClearInputProps : undefined}
    />
  );
}
