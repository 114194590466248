import { PropsWithChildren } from 'react';
import AppHeader from 'components/AppHeader';
import { ContentWrap, Wrap } from './styles';
import AppSidebar from '../AppSidebar';

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <AppHeader />
      <Wrap>
        <AppSidebar />
        <ContentWrap>{children}</ContentWrap>
      </Wrap>
    </>
  );
};

export default Layout;
