import styled from '@emotion/styled';
import { InvoiceStatus } from 'api/queries/projects/get-one';

function getColor(status: InvoiceStatus) {
  switch (status) {
    case InvoiceStatus.BadDept:
      return {
        text: '#EF4444',
        background: '#FEF2F2',
      };
    case InvoiceStatus.Paid:
      return {
        text: '#22C55E',
        background: '#F0FDF4',
      };
    case InvoiceStatus.Sent:
    case InvoiceStatus.Draft:
    case InvoiceStatus.ReadyToSend:
    default:
      return {
        text: '#64748B',
        background: '#F8FAFC',
      };
  }
}

const Wrap = styled.div<{ status: InvoiceStatus }>`
  display: inline-block;
  background-color: ${({ status }) => getColor(status).background};
  color: ${({ status }) => getColor(status).text};
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 14px;
`;

interface ChipProps {
  status: InvoiceStatus;
  color?: string;
}

function Chip({ status }: ChipProps) {
  return <Wrap status={status}>{status}</Wrap>;
}

export default Chip;
