import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const authSchema = z.object({
  email: z.string().email('Wrong email format'),
  password: z.string().min(1, 'Password is required'),
});

export type AuthFormSchema = z.infer<typeof authSchema>;

export default zodResolver(authSchema);

export const defaultValues: AuthFormSchema = {
  email: '',
  password: '',
};
