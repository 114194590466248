import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

export function handleAxiosError(error: AxiosError) {
  const { status } = error?.response || {};
  if (status === 400 || (status && status % 500 < 100)) {
    enqueueSnackbar('Something went wrong', { variant: 'warning', preventDuplicate: true });
  } else {
    throw error;
  }
}
