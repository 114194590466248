import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

const tableSx = {
  border: 'none',
  '.MuiPaper-root': {
    borderRadius: '8px',
    boxShadow: '0px 1px 2px 0px rgba(2, 6, 23, 0.08)',
  },
};

interface WrapProps extends PropsWithChildren {
  noData?: boolean;
}

function Wrap({ children, noData = false }: WrapProps) {
  return (
    <Box sx={tableSx} height={noData ? '313px' : '65vh'}>
      {children}
    </Box>
  );
}

export default Wrap;
