import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';

export default function LoadingFooter() {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={4}>
      <Typography variant={'body1'}>Loading...</Typography>

      <CircularProgress size={24} />
    </Box>
  );
}
