export default {
  purple: {
    main: '#8585FF',
    dark: '#6C6CE6',
    light: '#CFDDFF',
  },
  blue: {
    main: '#4987FF',
    calendar: 'rgba(23,118,209,0.25',
  },
  slate: {
    main: '#e2e8f0',
    950: '#020617',
  },
  grey: {
    main: '#97A2B6',
    dark: '#475467',
    light: '#F8F8FF',
    calendar: 'hsla(0,0%,60%,0.25)',
  },
  black: {
    main: '#27273D',
  },
  yellow: {
    calendar: 'rgba(248,169,36,0.25)',
  },
  red: {
    calendar: 'rgba(249,161,155,0.25)',
  },
};
