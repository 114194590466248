import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { ProjectData, UserData } from 'api/types';
import { API_PROJECTS } from 'constants/apiUrl';

export type AgreementBasis = 'Hourly' | 'Daily' | 'Monthly' | 'Flat';

export type Document = {
  id: number;
  name: string;
  extension: string;
  mimeType: string;
  projectId: number;
};

export type Agreement = {
  id: number;
  employeeId: number;
  projectId: number;
  startDate: string;
  endDate: string;
  allocation: number;
  rate: number;
  dailyRate: number;
  amount: number;
  basis: AgreementBasis;
  employee: {
    id: number;
    avatar: string | null;
    email: string;
    firstName: string;
    lastName: string;
    position: string;
  };
};

export enum InvoiceStatus {
  Draft = 'Draft',
  Sent = 'Sent',
  ReadyToSend = 'ReadyToSend',
  Paid = 'Paid',
  BadDept = 'Bad Debt',
}

type Invoice = {
  amount: number;
  currency: string;
  dateFrom: string;
  dateSent: string;
  dateTo: string;
  dueDate: string;
  id: number;
  invoiceNumber: `${number}`;
  paidDate: string;
  status: InvoiceStatus;
};

export type UserRelations = {
  users: UserData[];
  agreements: Agreement[];
  invoices: Invoice[];
  documents: Document[];
};

export type GetProjectResponse = ProjectData & UserRelations;

async function getOneProject(projectId: string) {
  const response = await axiosInstance.get<GetProjectResponse>(API_PROJECTS + '/' + projectId);
  return response.data;
}

export const getProjectOneQueryKey = (projectId: string) => ['project-get-one', projectId] as const;

export function useProjectGetOneQuery(projectId: string, enabled: boolean = true) {
  return useQuery({
    queryKey: getProjectOneQueryKey(projectId),
    queryFn: () => getOneProject(projectId),
    enabled: !!projectId && enabled,
    refetchOnWindowFocus: false,
  });
}
