import styled from '@emotion/styled';
import colors from 'constants/colors';

export const Wrap = styled.div`
  display: flex;
  height: 67px;
  border-bottom: 1px solid ${colors.slate.main};
  padding-right: 32px;

  @media screen and (max-width: 515px) {
    justify-content: space-between;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  border-right: 1px solid ${colors.slate.main};
  margin-right: auto;

  @media screen and (max-width: 515px) {
    display: none;
  }
`;

export const InnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 21px;
`;
