import { Button, ButtonProps, CircularProgress, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import colors from 'constants/colors';

interface ButtonWithPreloaderProps extends ButtonProps {
  loading?: boolean;
}

const buttonStyles: SxProps<Theme> = () => ({
  width: '100%',
  backgroundColor: colors.purple.main,
  boxShadow: 'none',
  padding: '12px 20px',
  borderRadius: '8px',
  fontSize: '16px',
  textTransform: 'unset',
  color: 'white',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: colors.purple.dark,
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: colors.purple.light,
  },
});

const circularProgressStyles: SxProps<Theme> = () => ({
  position: 'absolute',
  circle: {
    color: colors.purple.main,
  },
});

export default function ButtonWithPreloader({ disabled, loading, children, ...rest }: ButtonWithPreloaderProps) {
  return (
    <Button sx={buttonStyles} disabled={disabled || loading} {...rest}>
      {loading && <CircularProgress size="1.4rem" sx={circularProgressStyles} />}
      <span
        style={{
          visibility: loading ? 'hidden' : 'visible',
        }}
      >
        {children}
      </span>
    </Button>
  );
}
