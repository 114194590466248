import styled from '@emotion/styled';
import colors from 'constants/colors';

export const Section = styled.div<{ backgroundColor?: string }>`
  border: 3px solid red;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${colors.slate.main};
  box-shadow: 0px 1px 2px 0px rgba(2, 6, 23, 0.08);
  background-color: ${({ backgroundColor = 'white' }) => backgroundColor};
`;

export const InformationWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 24px;
`;

export const ListItem = styled.li`
  margin-bottom: 8px;
  > a {
    border-bottom: 1px solid;
    :hover {
      color: ${colors.purple.main};
      border-color: ${colors.purple.main};
    }
  }
`;
