import { SxProps, Theme } from '@mui/material';

export const adaptiveColumnBoxSx: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: 2,
  [theme.breakpoints.up('tablet')]: {
    padding: 4,
  },
});
