import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_PROJECTS_SET_DESCRIPTION } from 'constants/apiUrl';

export type ProjectSetDescriptionParams = {
  projectId: string;
  description: string;
};

async function setProjectDescription(params: ProjectSetDescriptionParams) {
  await axiosInstance.put(API_PROJECTS_SET_DESCRIPTION, JSON.stringify(params));
}

export function useProjectSetDescription() {
  return useMutation({
    mutationKey: ['set-project-description'],
    mutationFn: setProjectDescription,
  });
}
