import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\-!?_><&]{8,}$/;
const passwordRegexMessage =
  'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number';

const inviteSchema = z
  .object({
    password: z.string().min(1, 'Password is required').regex(passwordRegex, passwordRegexMessage),
    repeatPassword: z.string().min(1, 'Repeat Password is required'),
  })
  .refine(({ password, repeatPassword }) => password === repeatPassword, {
    message: "Password and Repeat Password don't match",
    path: ['repeatPassword'],
  });

export type InviteFormSchema = z.infer<typeof inviteSchema>;

export default zodResolver(inviteSchema);

export const defaultValues: InviteFormSchema = {
  password: '',
  repeatPassword: '',
};
