import { TableVirtuoso } from 'react-virtuoso';
import { Navigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import generateHeaderContent from 'components/table/header-content';
import generateVirtuosoTableComponents from 'components/table/virtuoso.common';
import { useAuth } from 'context/auth';
import { Agreement, useAgreementsHistory } from 'api/queries/agreements/get-history';
import { useProjectGetOneQuery } from 'api/queries/projects/get-one';
import Wrap from 'components/table/Wrap';
import { itemContent } from './components/RowContent';
import { columns } from './config';
import { ClientAgreementHistoryTableItem } from './types';

const VirtuozoTableComponents = generateVirtuosoTableComponents(columns);
const fixedHeaderContent = generateHeaderContent(columns);

export default function ClientAgreementHistoryTable() {
  const { agreementId = '' } = useParams();
  const { selectedProjectId } = useAuth();
  const { data: historyData } = useAgreementsHistory(agreementId, selectedProjectId ?? '');
  const { data: selectedProjectAgreements } = useProjectGetOneQuery(selectedProjectId || '');
  const rows = useMemo(() => dataAdapter(historyData?.data), [historyData]);

  const currentAgreementsForSelectedProject =
    selectedProjectAgreements?.agreements.filter(({ startDate, endDate }) => {
      const today = dayjs().startOf('day');
      const startedTodayOrBefore = dayjs(startDate).isSame(today, 'day') || dayjs(startDate).isBefore(today);
      const endsTodayOrAfter = dayjs(endDate).isSame(today, 'day') || dayjs(endDate).isAfter(today);

      return startedTodayOrBefore && endsTodayOrAfter;
    }) ?? [];
  const currentProjectsIncludesAgreement = currentAgreementsForSelectedProject.some(
    ({ employeeId }) => String(employeeId) === agreementId,
  );

  if (!currentProjectsIncludesAgreement) return <Navigate to="/agreements" replace />;

  return (
    <Wrap noData={rows.length === 0}>
      <TableVirtuoso
        fixedHeaderContent={fixedHeaderContent}
        itemContent={itemContent}
        data={rows}
        components={VirtuozoTableComponents}
      />
    </Wrap>
  );
}

function dataAdapter(agreements: Agreement[] | undefined): ClientAgreementHistoryTableItem[] {
  if (!agreements) return [];
  const now = dayjs();

  const currentAgreementsIds = agreements
    .filter((a) => dayjs(a.startDate).isBefore(now) && dayjs(a.endDate).isAfter(now))
    .map((a) => a.id);
  const futureAgreementsIds = agreements.filter((a) => dayjs(a.startDate).isAfter(now)).map((a) => a.id);

  const sortedAgreements = agreements.toSorted((a, b) => dayjs(b.endDate).diff(dayjs(a.endDate)));
  const hasFutureAgreements = futureAgreementsIds.length > 0;
  const preparedAgrrents = sortedAgreements.map((a) => {
    const isFutureAgreement = dayjs(a.startDate).isAfter(now);
    const isCurrentAgreement = currentAgreementsIds.includes(a.id);

    let hideEndDate = false;
    if (isFutureAgreement) hideEndDate = true;
    if (!hasFutureAgreements && isCurrentAgreement) hideEndDate = true;

    return { ...a, hideEndDate };
  });

  return preparedAgrrents;
}
