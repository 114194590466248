import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ProjectData } from 'api/types';
import { API_PROJECTS } from 'constants/apiUrl';
import axiosInstance from 'api/axios/instance';

export type ProjectsGetManyParams = {
  page: number;
  query?: string;
};

export type ProjectsGetManyResponse = {
  page: number;
  pageSize: number;
  count: number;
  data: ProjectData[];
};

export const PROJECTS_PAGE_SIZE = 50;

async function getManyProjects(params: ProjectsGetManyParams) {
  const result = await axiosInstance.get<ProjectsGetManyResponse>(API_PROJECTS, {
    params: {
      ...params,
      pageSize: PROJECTS_PAGE_SIZE,
    },
  });
  return result.data;
}

export const getProjectsManyQueryKey = (query?: string) => ['projects-get-many', query] as const;

export function useProjectsGetManyQuery(params: Pick<ProjectsGetManyParams, 'query'>, enabled = true) {
  const [queryKey, queryFn, getNextPageParam] = useMemo(
    () =>
      [
        getProjectsManyQueryKey(params.query),
        ({ pageParam = 0 }: { pageParam: number }) => getManyProjects({ ...params, page: pageParam }),
        (lastPage: ProjectsGetManyResponse) => {
          if (lastPage.count > lastPage.pageSize * (lastPage.page + 1)) {
            return lastPage.page + 1;
          }
          return undefined;
        },
      ] as const,
    [params.query],
  );

  return useInfiniteQuery({
    queryKey,
    queryFn,
    getNextPageParam,
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled,
  });
}
