import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PROJECTS_PAGE_SIZE, useProjectsGetManyQuery } from 'api/queries/projects/get-many';
import { SuperAdminProjectTableItem } from '../types';

export default function useProjectsTableData() {
  const [params] = useSearchParams();

  const filterToken = (params.get('q') || '').toLowerCase().trim();

  const queryMeta = useProjectsGetManyQuery({
    query: filterToken,
  });

  const tableData = useMemo(() => {
    return (queryMeta.data?.pages || [])
      .flatMap((p) => p.data)
      .map<SuperAdminProjectTableItem>((project) => ({
        id: project.id,
        name: project.name,
        department: '',
        startDate: new Date(project.startDate).getTime(),
        manager: {
          name: [project.projectManager.firstName, project.projectManager.lastName].filter(Boolean).join(' '),
          email: '',
        },
        manageLink: '',
      }));
  }, [queryMeta.data]);

  const isLoadingMore = queryMeta.isLoading || queryMeta.isFetchingNextPage;
  const canLoadMore =
    typeof queryMeta.data === 'object'
      ? queryMeta.data.pages.length * PROJECTS_PAGE_SIZE < queryMeta.data.pages[0].count
      : true;

  const loadMore = useCallback(
    (isBottom: boolean) => {
      if (isBottom && !isLoadingMore && canLoadMore) {
        queryMeta.fetchNextPage();
      }
    },
    [isLoadingMore, canLoadMore, queryMeta],
  );

  return [tableData, queryMeta.isLoading || queryMeta.isFetchingNextPage, loadMore, queryMeta] as const;
}
