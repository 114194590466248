import { TableVirtuoso } from 'react-virtuoso';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import generateVirtuosoTableComponents from 'components/table/virtuoso.common';
import generateHeaderContent from 'components/table/header-content';
import { useGetAbailableResources } from 'api/queries/available-resources/get-available-resources';
import { Agreement } from 'api/queries/projects/get-one';
import Wrap from 'components/table/Wrap';
import { itemContent } from './components/RowContent';
import { columns } from './config';
import { ClientResourcesTableItem } from './types';

const VirtuozoTableComponents = generateVirtuosoTableComponents(columns);
const fixedHeaderContent = generateHeaderContent(columns);

export default function ClientResourcesTable() {
  const { data } = useGetAbailableResources();
  const rows = useMemo(() => dataAdapter(data?.agreements), [data]);

  return (
    <Wrap noData={rows.length === 0}>
      <TableVirtuoso
        fixedHeaderContent={fixedHeaderContent}
        itemContent={itemContent}
        data={rows}
        components={VirtuozoTableComponents}
      />
    </Wrap>
  );
}

function dataAdapter(agreements: Agreement[] | undefined): ClientResourcesTableItem[] {
  if (!agreements) return [];

  return agreements
    .filter((agreement) => !dayjs(agreement.endDate).isBefore(dayjs()))
    .map((agreement) => ({
      id: agreement.id,
      name: agreement.employee.firstName,
      position: agreement.employee.position,
      seniority: '-', // TODO: add real
      availableFromDate: agreement.startDate,
      allocation: agreement.allocation,
    }));
}
