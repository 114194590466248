import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, SxProps } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useNavigationOptions } from './options';

const paperStyles: SxProps = { width: { mobile: '100%' } };

interface AppDrawerProps {
  open: boolean;
  handleClose(): void;
}

export default function AppDrawer({ open, handleClose }: AppDrawerProps) {
  const { pathname } = useLocation();
  const navOptions = useNavigationOptions();

  return (
    <Drawer variant="temporary" anchor="left" open={open} onClose={handleClose} PaperProps={{ sx: paperStyles }}>
      <Box textAlign="left" pt={1}>
        <IconButton color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        {navOptions.map(({ name, Icon, to }) => (
          <>
            <Divider />
            <ListItem key={name} disablePadding>
              <ListItemButton selected={pathname === to} component={RouterLink} to={to} onClick={handleClose}>
                <Icon />
                <ListItemText sx={{ ml: 2 }} primary={name} />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </Drawer>
  );
}
