import styled from '@emotion/styled';
import { Link as _Link } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { ReactComponent as Icon } from 'assets/icons/arrow-right.svg';
import colors from 'constants/colors';

const Link = styled(_Link)`
  color: #475467;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 16px;

  :hover {
    color: ${colors.purple.main};

    svg > path {
      fill: ${colors.purple.main};
    }
  }
`;

interface BackLinkProps extends PropsWithChildren {
  to: string;
}

function BackLink({ children, to }: BackLinkProps) {
  return (
    <Link to={to}>
      <Icon />
      {children}
    </Link>
  );
}

export default BackLink;
