import { lazy, Suspense } from 'react';
import PageLoader from 'components/PageLoader';

const CalendarPage = lazy(() => import('./component'));

export default function CalendarPageLoader() {
  return (
    <Suspense fallback={<PageLoader />}>
      <CalendarPage />
    </Suspense>
  );
}
