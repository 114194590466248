import { Outlet, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useAuth } from 'context/auth';
import Loader from 'components/UI/Loader';

export default function HomePage() {
  const { pathname } = useLocation();
  const { isLoading, role, meta } = useAuth();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);

  useEffect(() => {
    ReactGA.event({
      action: 'user_info',
      category: 'Info',
      label: `PROJECT_NAME: ${meta?.projectName}, FULL_NAME: ${meta?.fullName}, ROLE: ${role}, PATH: ${pathname}`,
    });
  }, [meta?.fullName, meta?.projectName, pathname, role]);

  return isLoading ? <Loader /> : <Outlet />;
}
