import { SuperAdminProjectColumnData } from './types';

export const columns: SuperAdminProjectColumnData[] = [
  {
    width: 200,
    label: 'Project Name',
    itemKey: 'name',
  },
  {
    width: 200,
    label: 'Project Manager',
    itemKey: 'manager',
  },
  {
    width: 120,
    label: 'Project Start Date',
    itemKey: 'startDate',
  },
  { width: 100, label: '', itemKey: 'manageLink' },
];
